/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-16 14:56:03
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-16 15:00:27
 */
import AdminOverview from './Overview.vue'
export default AdminOverview
